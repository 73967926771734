
import './back.scss';
import { useHistory } from "react-router-dom";

function Back() {
  const history = useHistory();

  const goback = () => {
    history.goBack()
    // window.scrollTo()
  }
  return (
    <div className="go-back" onClick={goback}>
      <img src="https://img.icons8.com/ios-glyphs/30/000000/back.png" />
    </div>
  );
}

export default Back;
