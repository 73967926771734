
import './head.scss';
import { useState, Fragment } from 'react';
import Back from '../Back/Back';
const Head = ({ product }) => {

  return (
    <div className="header-div">
      <Back />
        <h1>BOne</h1>
    </div>
  );
}

export default Head;
