import { Fragment, useState, useEffect } from 'react';
import './producDetails.scss';
import { BrowserRouter, Route, Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { addtocart } from '../../browserstore/actions/index'
import { useDispatch, useSelector } from "react-redux";
function ProducDetails(props) {
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const [productId, setProductId] = useState(props.match.params.id);
    const [productDetail, setProductDetail] = useState({});

    useEffect(() => {
        console.log('cart',cart);
        setProductDetail({ 'id': 1, 'name': 'T- Shirt 01', 'image': ['/images/shirt-one.jpg', 'https://images.meesho.com/images/products/18457561/ef507_512.jpg', 'https://images.meesho.com/images/products/30453229/7bb0f_512.jpg'], 'price': '$4', 'is_fav': 0, 'original_price': '$10' })
        console.log('productDetail', productDetail)
    }, [])

    const viewImage = index => {
        console.log('image index', index)
    }

    const addToCart = () => {
        console.log('add to cart');
        dispatch(addtocart(productDetail));
    }

    const redirectToInstagram = () => {
        var input = document.createElement('textarea');
        input.innerHTML = 'https://www.instagram.com/p/CRisSNCHDUE/?utm_source=ig_web_copy_link';
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        window.open('https://www.instagram.com/thebeonestore/');
    }

    return (
        <div className="product-detail">
            <div className="carousel-div">
                <Carousel axis={'horizontal'} onClickItem={viewImage} infiniteLoop={true} showStatus={false} showThumbs={false} >
                    {productDetail && productDetail.image && productDetail.image.map(i => <div>
                        <img src={i} />
                    </div>
                    )}
                </Carousel>
            </div>
            <div className="product-detail-div">
                <div class="details-small">
                    <div class="product-type">Casual T Shirts</div>
                    <div class="product-name">T- Shirt 05</div><div class="product-price">$32 | <span class="product-original-price">$100</span> | <span class="offer-percentage">68.00 %</span>
                    </div>
                </div>
                <div className="size-chart">
                    <div>S</div>
                    <div>M</div>
                    <div>L</div>
                    <div>XL</div>
                </div>
                <div className="detail-div">
                <div className="details">
                    <div className="product-details-heading">
                        Products Details
                    </div>
                    <div className="product-details">
                        cotton T-shirt neck, 3/4 sleeves
                    </div>
                </div>
                <div className="details">
                    <div className="product-details-heading">Meterial</div>
                    <div className="product-details">100% cotton</div>
                </div>
                <div className="details">
                    <div className="product-details-heading">Color</div>
                    <div className="product-details">White</div>
                </div>
            </div>
            </div>
            {/* Color
            <div className="color-chart">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div> */}
            <div className="add-to-cart-div" onClick={addToCart}>
                <button className="add-to-cart-btn" onClick={redirectToInstagram}>BUY NOW</button>
            </div>
        </div>
    );
}

export default ProducDetails;
