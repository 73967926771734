
import './viewImage.scss';
import { useState, Fragment } from 'react';
const ViewImage = ({ image }) => {

  return (
    <div className="view-image">
        <span>X</span>
        <img src={image} />
    </div>
  );
}

export default ViewImage;
