import { Fragment, useState } from 'react';
import CardSmall from "./../../components/card-small/CardSmall";
import './home.scss';
import { BrowserRouter, Route, Link } from "react-router-dom";

function Home() {
    const [product, setProduct] = useState([
        { 'id': 1, 'name': 'T- Shirt 01', 'image': '/images/shirt-one.jpg', 'price': '$4', 'is_fav': 0, 'original_price': '$10' },
        // { 'id': 2, 'name': 'T- Shirt 02', 'image': 'https://images.meesho.com/images/products/5301281/1_512.jpg', 'price': '$54', 'is_fav': 0, 'original_price': '$100' },
        // { 'id': 3, 'name': 'T- Shirt 03', 'image': 'https://images.meesho.com/images/products/1646483/1_512.jpg', 'price': '$43', 'is_fav': 1, 'original_price': '$100' },
        // { 'id': 4, 'name': 'T- Shirt 04', 'image': 'https://images.meesho.com/images/products/1703821/1_512.jpg', 'price': '$45', 'is_fav': 0, 'original_price': '$100' },
        { 'id': 5, 'name': 'T- Shirt 05', 'image': 'https://images.meesho.com/images/products/18457561/ef507_512.jpg', 'price': '$32', 'is_fav': 0, 'original_price': '$100' },
        // { 'id': 6, 'name': 'T- Shirt 06', 'image': 'https://images.meesho.com/images/products/26777527/hum3y_512.jpg', 'price': '$12', 'is_fav': 0, 'original_price': '$100' },
        { 'id': 7, 'name': 'T- Shirt 07', 'image': 'https://images.meesho.com/images/products/30453229/7bb0f_512.jpg', 'price': '$64', 'is_fav': 0, 'original_price': '$100' },
        { 'id': 8, 'name': 'T- Shirt 08', 'image': 'https://images.meesho.com/images/products/20392778/9ec0b_512.jpg', 'price': '$103', 'is_fav': 0, 'original_price': '$150' },
        // { 'id': 9, 'name': 'T- Shirt 09', 'image': 'https://images.meesho.com/images/products/5442785/1_512.jpg', 'price': '$7', 'is_fav': 0, 'original_price': '$10' },
        { 'id': 10, 'name': 'T- Shirt 10', 'image': 'https://images.meesho.com/images/products/5690024/1_512.jpg', 'price': '$3', 'is_fav': 0, 'original_price': '$10' }
    ])
    console.log('product', product);
    return (
        <div className="home">
            {product.map((p, i) => {
                return <Fragment key={p.id}>
                    <CardSmall key={p.id} product={p} />
                </Fragment>
            })}
        </div>
    );
}

export default Home;
