/** set loading true or false */
export const loading = (payload) => ({
    payload: payload,
    type: 'LOADING'
})

/** add product to cart */
export const addtocart = payload => ({
        payload:payload,
        type: 'ADD_TO_CART'
})