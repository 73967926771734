
import './footer.scss';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <Link to="/">
      <div id="home">
        <img src="https://img.icons8.com/material-rounded/24/000000/home.png" />
      </div>
      </Link>
      <Link to="/categories">
      <div id="to-collection">
        <img src="https://img.icons8.com/ios-glyphs/24/000000/categorize.png" />
      </div>
      </Link>
      <Link to="/bag">
      <div id="to-cart">
        <img src="https://img.icons8.com/ios-filled/24/000000/shopping-bag.png" />
      </div>
      </Link>
      <Link to="/account">
      <div id="to-account">
        <img src="https://img.icons8.com/fluent-systems-filled/24/000000/user.png" />
      </div>
      </Link>
    </div>
  );
}

export default Footer;
