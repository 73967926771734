
import './card-small.scss';
import { useState, Fragment } from 'react';
import ViewImage from '../ViewImage/ViewImage';
import { Link } from "react-router-dom";

const CardSmall = ({ product }) => {
  const [productPage, setProductPage] = useState(false);
  const [viewimage, setViewimage] = useState(false);

  const getPercentage = (price, original_price) => {
    original_price = original_price.replace('$', '');
    price = price.replace('$', '');
    return `${(((original_price - price) * 100) / original_price).toFixed(2)} %`;
  }

  const openviewimage = (e, product) => {
    if (productPage) {
      setViewimage(true);
    } else {
      setViewimage(false);
    }
  }

  return (
    <Link to={`/product/${product.id}`}>
      <div className="card-small">
        <div className="overlay"></div>
        <div className="card-small-scroll">
          <div className="img-div">
            <img onClick={(e) => openviewimage(e, product)} src={product.image ? product.image : 'https://images.meesho.com/images/products/26621480/lvb8u_512_512.jpg'} />
          </div>
          {productPage && <div>. . .</div>}
          <div className="details-small">
            <div className="product-type">Casual T Shirts</div>
            <div className="product-name">{product.name}</div>
            <div className="product-price">{product.price} | <span className="product-original-price">{product.original_price}</span> | <span className="offer-percentage">{getPercentage(product.price, product.original_price)}</span></div>
            {productPage &&
              <Fragment>
                <div className="size-chart">
                  <div>S</div>
                  <div>M</div>
                  <div>L</div>
                  <div>XL</div>
                </div>
                <div className="details">
                  <div className="product-details-heading">
                    Products Details
                  </div>
                  <div className="product-details">
                    cotton T-shirt neck, 3/4 sleeves
                  </div>
                </div>
                <div className="details">
                  <div className="product-details-heading">Meterial</div>
                  <div className="product-details">100% cotton</div>
                </div>
                <div className="details">
                  <div className="product-details-heading">Color</div>
                  <div className="product-details">White</div>
                </div>
              </Fragment>}
            {/* <div className="price-small">
          <div>{product.price}</div>
          <div>{product.is_fav}</div>
        </div> */}
          </div>
        </div>
        {productPage && productPage === true && viewimage && viewimage === true && <ViewImage image={product.image} />}
      </div>
    </Link>
  );
}

export default CardSmall;
