const reducer = (state = {cart:[]}, action) => {
    console.log('payload', action);
    switch (action.type) {
        case 'LOADING':
            return { ...state, loading: action.payload }
        case 'ADD_TO_CART':
            return {...state, cart: [...state.cart, action.payload]}
        default:
            return state;
    }
}

export default reducer;