import { Fragment, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Footer from "./components/footer/Footer";
import Head from './components/Head/Head';
import './starter.css';
import './app.css';
import { Switch, Route, Link, useLocation } from "react-router-dom";
import Home from './pages/Home/Home';
import ProducDetails from './pages/ProductDetails/ProducDetails';

function App(props) {
  const footer = useSelector(state => state.footer);
  const location = useLocation()

  useEffect(() => {
    console.log('Location changed', location);
  }, [location]);
  return (
    <div className="App">
        <Head />
        <div className="main-div">
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/product/:id' component={ProducDetails} />
          {/* <Route exact path='/' component={Home} /> */}
        </Switch>
        </div>
        {!location.pathname.includes('/product/') && <Footer />}
    </div>
  );
}

export default App;
